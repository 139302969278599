// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// @import '~handsontable/dist/handsontable.full.css';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mi-ruta-primary: mat.define-palette(mat.$indigo-palette);
$mi-ruta-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$mi-ruta-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$mi-ruta-theme: mat.define-light-theme(
    (
        color: (
            primary: $mi-ruta-primary,
            accent: $mi-ruta-accent,
            warn: $mi-ruta-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mi-ruta-theme);

/* You can add global styles to this file, and also import other style files */

@import "../node_modules/angular-calendar/css/angular-calendar.css";

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

//*************************** Calendar ************************
.calendar-text {
    span {
        color: white !important;
        font-size: 12px;
    }
}

.cal-time.ng-star-inserted{
    background-color: #d1d1d1 !important;
    color: black !important;
    padding: 0 !important;
    position: relative !important;
    z-index: 9 !important;
}

.cal-hour.cal-hour-odd.ng-star-inserted {
    background-color: #f0f0f0 !important;
}

.calendar-text-yellow-background {
    span {
        color: black !important;
        font-size: 12px;
    }
}
//*************************** end Calendar ************************

.reload-loading-button {
    display: flex;
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
    border-radius: 50%;
    bottom: 180px;
    left: -30px;

    button {
        margin: auto;
    }
}
// .mat-slide-toggle-bar {
//     background-color: #e47171 !important;
// }
.close-loading-button {
    display: flex;
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
    border-radius: 50%;
    top: -10px;
    left: -30px;

    button {
        margin: auto;
    }
}

.carrousel {
    .mat-dialog-container {
        background-color: var(--background) !important;
        // border-color: var(--background) !important;
        box-shadow: var(--background) !important;
        width: 100vw !important;
        padding: 0 !important;
    }
}

.wider-mat-tooltip {
    white-space: pre-line;
    min-width: 380px;
    font-size: 12px;
}

.phone-mat-tooltip {
    white-space: pre-line;
    font-size: 16px;
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.9);
}

//*************************** Table rows styles ************************
tr.mat-header-row {
    height: 20px !important;
}

tr.mat-row {
    color: black !important;
    height: 28px !important;
    width: 20px !important;
}
//*************************** End Table rows styles ************************


//*************************** Calendar styles ************************
.cal-day-headers {
    height: 51px !important;
    width: 100%;
    position: fixed !important;
    top: 100px !important;
    z-index: 99 !important; /* Ensure it stays on top of other elements */
    background-color: white; /* To prevent content underneath from showing through */
}
//*************************** End Calendar styles ************************

.search-input {
    position: fixed;
    top: 10px;
    left: 50%;
    background-color: white;
    border-radius: 10px;
    transform: translateX(-50%);
    display: flex;

    mat-form-field {
        width: 400px;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.wtHolter {
    overflow-y: auto !important;
}

.fa-icon-column {
    // color: #777777;
    color: #2f7cb4;
    font-size: 20px;
}

.div_background_slider {
    background-color: white;
    border-radius: 5px;
    padding: 8px;
}

.top-left-button-filter {
    position: absolute;
    top: 10px;
    left: 195px;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #2a79ff !important;
}

.centered-spinner {
    position: absolute;
    top: -moz-calc(50% - 50px) !important;
    /* WebKit */
    top: -webkit-calc(50% - 50px) !important;
    /* Opera */
    top: -o-calc(50% - 50px) !important;
    /* Standard */
    top: calc(50% - 50px) !important;

    left: -moz-calc(50% - 50px) !important;
    /* WebKit */
    left: -webkit-calc(50% - 50px) !important;
    /* Opera */
    left: -o-calc(50% - 50px) !important;
    /* Standard */
    left: calc(50% - 50px) !important;
}

.raise-filter-button {
    width: 50px;
    height: 40px;
    color: '#FFFFFF';
}

//Modifiy display of clock (time selector) //////////////////////////////////////
.timepicker-button {
    color: #368dce !important;
}
.timepicker-dial {
    background-color: #368dce !important;
}
.timepicker__header {
    background-color: #368dce !important;
}
.clock-face span.active {
    background-color: #368dce !important;
}
.clock-face__clock-hand {
    background-color: #368dce !important;
}
.clock-face__clock-hand_minute {
    background-color: #368dce !important;
}

.timepicker-overlay {
    z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
    z-index: 1100 !important;
}

//? Autocomplete CSS modification /////////////////////////////////////////////////
.pac-item-query {
    font-size: 15px;
    padding-right: 3px;
    color: #000;

    .pac-matched {
        font-weight: 700;
    }
}

//? Autocomplete CSS modification /////////////////////////////////////////////////

mat-tab-group {
    width: 100%;
    flex: 10;
    /* Firefox */
    max-height: -moz-calc(100% - 136px);
    /* WebKit */
    max-height: -webkit-calc(100% - 136px);
    /* Opera */
    max-height: -o-calc(100% - 136px);
    /* Standard */
    max-height: calc(100% - 136px);
    background-color: white;
}

//////////////////////////// toolbar ////////////////////////////////////
.fa-icon-column {
    // color: #777777 !important;
    color: #368dce !important;
    font-size: 16px !important;
    padding: 10px !important;
    padding-left: 8px !important;
    padding-right: 20px !important;
}
mat-toolbar {
    width: 100%;
    height: 64px;
    max-height: 64px;
}
.mat-input-search {
    position: absolute !important;
    right: 0.9em !important;
    top: 0.6em !important;
}
.example-spacer {
    flex: 1 1 auto !important;
}
//////////////////////////// toolbar ////////////////////////////////////

/////////////////////// mat-drawer ////////////////////////////////////////////////
mat-drawer-content {
    display: flex !important;
    flex-direction: column !important;
    // justify-content: stretch !important;
    height: 100% !important;
    max-height: 100% !important;
    background-color: white !important;
    // overflow: hidden !important;
}
mat-drawer-container {
    height: 100% !important;
}
mat-drawer {
    height: 100% !important;
    text-align: center !important;
}
.mat-drawer-inner-container {
    background-color: transparent !important;
}

.mat-tab-label-container {
    background-image: linear-gradient(90deg, #1b4667, rgba(255, 255, 255, 0) 70%) !important;
}
.mat-tab-label-active {
    //Not change opacity on active tab label when changing focus
    opacity: 1 !important;
}

.mat-drawer-side {
    border-right: 0 !important;
}
.drawer-background {
    background-image: linear-gradient(45deg, #0d2334, rgba(255, 255, 255, 0) 70%) !important;
    background-color: #2f7cb4 !important; //primary at 115%
    app-drawer .shape {
        z-index: -1 !important;
        position: fixed !important;
        bottom: 80% !important;
        left: -80px !important;
        height: 200px !important;
        width: 200px !important;
        background-color: #368dce !important;
        border-radius: 40px !important;
        float: left !important;
        transform: rotate(60deg) !important;
    }
}
.drawer-button {
    font-size: 16px !important;
    width: 250px !important;
    text-align: left !important;
    padding: 10px !important;
    font-weight: 400 !important;
    color: white !important;
    background-color: transparent !important;
}
.mat-icon-drawer {
    color: white !important;
    font-size: 24px !important;
    padding: 10px !important;
    padding-right: 30px !important;
    padding-left: 40px !important;
}
.fa-icon-drawer {
    font-size: 20px !important;
    padding: 10px !important;
    padding-right: 30px !important;
    padding-left: 40px !important;
}
.mat-drawer-items {
    position: relative !important;
    top: 40% !important;
    transform: translateY(-50%) !important;
}
.input-hidden {
    display: block !important;
    visibility: hidden !important;
    width: 0 !important;
    height: 0 !important;
}
/////////////////////// mat-drawer ////////////////////////////////////////////////

////////////////////// footer ////////////////////////////////////////////////
footer {
    display: flex;
    width: 100%;
    height: 72px;
    max-height: 72px;
    z-index: 999;
    background-color: white;
}

section {
    display: table;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.example-button-row {
    display: table-cell;
    width: 400px;
}

.example-button-row .mat-button-base {
    margin: 8px 8px 8px 0;
}

.example-flex-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.example-button-container {
    display: flex;
    justify-content: center;
    width: 90px;
}

mat-paginator {
    margin-left: auto;
}

.mat-drawer-inner-container {
    overflow-x: hidden !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: rgba(0, 0, 0, 0.1) !important;
    font-weight: bold !important;
}

.pac-logo:after {
    background-image: none !important;
}
.pac-container {
    border-radius: 8px !important;
}

////////////////////// footer ////////////////////////////////////////////////

//////////////////// colors /////////////////////////////////////////////
.excel-color {
    background-color: #1d6f42 !important;
}
.danger-color {
    background-color: #f44336 !important;
}
.white-color {
    background-color: #ffffff !important;
}
.whatsapp-color {
    background-color: #25d366 !important;
}
.acrobat-reader-color {
    background-color: #f40f02 !important;
}
//////////////////// colors /////////////////////////////////////////////

////////////////////// snackbar //////////////////////////////////////
.my-snack-bar-info {
    background-color: #368dce !important;
    color: #ffffff !important;
    .mat-simple-snackbar-action {
        font-weight: 3500;
        color: black !important;
    }
}
.my-snack-bar-warning {
    background-color: #ffc107 !important;
    color: black !important;
    .mat-simple-snackbar-action {
        color: white !important;
    }
}
.my-snack-bar-error {
    background-color: #f44336 !important;
    color: #ffffff !important;
    .mat-simple-snackbar-action {
        color: white !important;
    }
}
////////////////////// snackbar //////////////////////////////////////

///////////////// positioning //////////////////////////////////
.center-child {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.top-left-child {
    /* Center vertically */
    position: absolute !important;
    top: 0% !important;
    left: 0% !important;
    padding: 5px !important;
    z-index: 999 !important; //send to front of elements of z-index minus to 999
}
///////////////// positioning //////////////////////////////////

///////////////// tables //////////////////////////////////
.flex-container {
    //flex to fill the space
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    flex: 10 !important;
    /* Firefox */
    max-height: -moz-calc(100% - 136px) !important;
    /* WebKit */
    max-height: -webkit-calc(100% - 136px) !important;
    /* Opera */
    max-height: -o-calc(100% - 136px) !important;
    /* Standard */
    max-height: calc(100% - 136px) !important;
    background-color: white !important;
}

.table-container {
    overflow: hidden !important;
    display: flex !important;
}

.inner-table-container {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    z-index: 1 !important;
    flex-grow: 1 !important;
    display: block !important;
    overflow: hidden !important;
    flex-basis: 100% !important;
}

.app-table-container {
    height: 100% !important;
    overflow: auto !important;
}

.mat-badge-content {
    font-size: 11px !important;
}
///////////////// tables //////////////////////////////////

.mat-dialog-container {
    overflow: hidden !important;
    padding: 0 auto !important;
}

//? Class for the loading dialog animation
.loading-text-background {
    //loading text for spinners
    padding: 10px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-radius: 20px !important;
    background-color: white !important;
    color: rgb(77, 77, 77) !important;
    // font-weight: bold !important;
    font-size: 1.2em !important;
    font-weight: 500;
    text-shadow: 0px 0px 20px white !important;
}

html,
body {
    height: 100%;
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.floating-top-left {
    display: flex;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 999;
    border-radius: 50%;
}
